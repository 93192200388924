<template>
    <div class="wrap">
        <!-- pc布局【订单列表】 -->
        <b-container class="pc-shop-list">
            <el-row>
                <el-col :span="4">
                    <div class="label-tit"><span class="label"><router-link class="lias" :to="{name:'myself'}">{{$t('message.accountinfo')}}</router-link></span></div>
                    <div class="label-tit"><span class="label cur">{{$t('message.myorder')}}</span></div>
                    <div class="label-tit"><span class="label"><router-link class="lias" :to="{name:'myaddress'}">{{$t('message.addressfrom')}}</router-link></span></div>
                </el-col>
                <el-col :span="20">
                    <div class="shop-group-box">
                        <div class="order-tab">
                            <div class="tab-name" :class="currentindex==-1?'cur':''" @click="getSwitchTab(-1)">{{$t('message.all')}}</div>
                            <div class="tab-name" :class="currentindex==(index)?'cur':''" @click="getSwitchTab(index)" v-for="(item,index) in orderStatusList" :key="index">{{item}}</div>
                        </div>
                        <div class="order-header">
                            <div class="head-th col1">{{$t('message.shop')}}</div>
                            <div class="head-th col2">{{$t('message.priceshop')}}</div>
                            <div class="head-th col3">{{$t('message.num')}}</div>
                            <div class="head-th col4">{{$t('message.allmpney')}}</div>
                            <div class="head-th col5">{{$t('message.tbmore')}}</div>
                        </div>
                        <div class="order-group-nodata" v-show="orderData.length==0">{{$t('message.nolist')}}~</div>
                        <div class="order-group">
                            <div class="order-item" v-for="(item,index) in orderData" :key="index">
                                <div class="title">
                                    <div class="orderleft">
                                        <div class="time">{{item.create_time}}</div>
                                        <div class="ordercode">{{$t('message.ordernum')}}：{{item.order_no}}</div>
                                    </div>
                                    <div class="orderstatus">
                                        {{item.order_status==0?$t('message.ruse'):''}}
                                        {{item.order_status==1?$t('message.writpaylab'):''}}
                                        {{item.order_status==2?$t('message.writfh'):''}}
                                        {{item.order_status==3?$t('message.writshlab'):''}}
                                        {{item.order_status==4?$t('message.successlab'):''}}
                                        {{item.order_status==5?$t('message.showlab'):''}}
                                    </div>
                                </div>
                                <div class="order-wrap">
                                    <div class="order-shop">
                                        <div class="order-shop-item" v-for="(val,key) in item.goods" :key="key">
                                            <div class="order-shop-img" @click="getShopInfo(val)">
                                                <div class="shop-img"><img :src="val.goods_img" alt=""></div>
                                                <div class="shop-title" v-show="languageName=='cn'">{{val.cn_name}}（{{val.cn_spect_name}}）</div>
                                                <div class="shop-title" v-show="languageName=='en'">{{val.en_name}}（{{val.en_spect_name}}）</div>
                                                <div class="shop-title" v-show="languageName=='it'">{{val.it_name}}（{{val.it_spect_name}}）</div>
                                            </div>
                                            <div class="order-shop-money">{{$t('message.m')}}{{val.price}}</div>
                                            <div class="order-shop-number">{{val.goods_num}}</div>
                                        </div>
                                    </div>
                                    <div class="order-money">
                                        <div class="cen">
                                            <div class="money">{{$t('message.m')}}{{item.totalmoney}}</div>
                                            <div class="money" v-show="(item&&item.totalaams!='0.00')">AAMS{{$t('message.m')}}{{item.totalaams}}</div>
                                            <div class="money" v-show="(item&&item.totaliva!='0.00')">IVA{{$t('message.m')}}{{item.totaliva}}</div>
                                            <div class="paytype">
                                                <span v-show="item.type2==1">【{{$t('message.zxpay')}}】</span>
                                                <span class="red" v-show="item.type2==2">【{{$t('message.hdfkpay')}}】</span>
                                            </div>
                                            <div class="post" v-show="item.is_no_postage!=1">({{$t('message.haspost')}}：{{$t('message.m')}}{{item.postage}})</div>
                                        </div>
                                    </div>
                                    <div class="order-more">
                                        <div class="cen" v-show="item.order_status==0"></div>
                                        <div class="cen" v-show="item.order_status==1">
                                            <div class="paybtn" @click="getSubmit(item)">{{$t('message.nowpay')}}</div>
                                            <div class="info-order"><router-link class="lias" :to="{name:'myorderinfo',query:{order_no:item.order_no}}">{{$t('message.orinfo')}}</router-link></div>
                                        </div>
                                        <div class="cen" v-show="item.order_status==2">
                                            <div class="info-order"><router-link class="lias" :to="{name:'myorderinfo',query:{order_no:item.order_no}}">{{$t('message.orinfo')}}</router-link></div>
                                        </div>
                                        <div class="cen" v-show="item.order_status==3">
                                            <div class="paybtn" @click="getSubmitReceiving(item)">{{$t('message.comfiresh')}}</div>
                                            <div class="info-order"><router-link class="lias" :to="{name:'logistics',query:{order_no:item.order_no}}">{{$t('message.loginfo')}}</router-link></div>
                                            <div class="info-order"><router-link class="lias" :to="{name:'myorderinfo',query:{order_no:item.order_no}}">{{$t('message.orinfo')}}</router-link></div>
                                        </div>
                                        <div class="cen" v-show="item.order_status==4">
                                            <div class="info-order"><router-link class="lias" :to="{name:'logistics',query:{order_no:item.order_no}}">{{$t('message.loginfo')}}</router-link></div>
                                            <div class="info-order" @click="getDelete(item,index)">{{$t('message.delorder')}}</div>
                                            <!-- type2==1线上支付展示售后申请，type2==2货到付款没有 -->
                                            <div class="info-order" v-show="item.type2==1">
                                                <router-link class="lias" :to="{name:'myordershowinfo',query:{order_no:item.order_no,type:1}}">{{$t('message.shoinfo')}}</router-link>
                                            </div>
                                            <div class="info-order"><router-link class="lias" :to="{name:'myorderinfo',query:{order_no:item.order_no}}">{{$t('message.orinfo')}}</router-link></div>
                                        </div>
                                        <div class="cen" v-show="item.order_status==5">
                                            <div class="info-order" v-show="item.type2==1"><router-link class="lias" :to="{name:'myordershowinfo',query:{order_no:item.order_no,type:3}}">{{$t('message.showstep')}}</router-link></div>
                                            <div class="info-order"><router-link class="lias" :to="{name:'myorderinfo',query:{order_no:item.order_no}}">{{$t('message.orinfo')}}</router-link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pc-page" v-show="rows>size">
                            <b-pagination :prev-text="$t('message.prev')" :next-text="$t('message.next')" v-model="currentPage" :total-rows="rows" :per-page="size" @change="handleCurrentChange"></b-pagination>    
                        </div>
                    </div>
                </el-col>
            </el-row>
        </b-container>
        <!-- wap布局【订单列表】 -->
        <b-container class="wap-navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.myorder')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <b-container class="wap-label">
            <van-tabs v-model="currenttabindex" @change="getSwitchTabWap">
                <van-tab :title="$t('message.all')" @change="getSwitchTabWap"></van-tab>
                <van-tab :title="item" v-for="(item,index) in orderStatusList" :key="index" @change="getSwitchTabWap"></van-tab>
            </van-tabs>
        </b-container>
        <b-container class="wap-shop-list">
            <div class="order-group-nodata" v-show="orderData.length==0">{{$t('message.nolist')}}~</div>
            <div class="wap-order-group">
                <div class="wap-order-item" v-for="(item,index) in orderData" :key="index">
                    <div class="wap-title">
                        <div class="wap-orderleft">
                            <div class="wap-ordercode">{{$t('message.ordernum')}}：{{item.order_no}}</div>
                        </div>
                        <div class="wap-orderstatus">
                            {{item.order_status==0?$t('message.ruse'):''}}
                            {{item.order_status==1?$t('message.writpaylab'):''}}
                            {{item.order_status==2?$t('message.writfh'):''}}
                            {{item.order_status==3?$t('message.writshlab'):''}}
                            {{item.order_status==4?$t('message.successlab'):''}}
                            {{item.order_status==5?$t('message.showlab'):''}}
                        </div>
                    </div>
                    <div class="wap-order-wrap">
                        <div class="wap-order-shop">
                            <div class="wap-order-shop-item" v-for="(val,key) in item.goods" :key="key">
                                <div class="wap-order-shop-img">
                                    <img :src="val.goods_img" alt="">
                                </div>
                                <div class="wap-order-shop-money" v-show="languageName=='cn'">
                                    <div class="wap-shop-title">{{val.cn_name}}</div>
                                    <div class="wap-shop-txt">{{val.cn_spect_name}}</div>
                                    <div class="wap-shop-money">{{$t('message.m')}}{{val.price}}</div>
                                </div>
                                <div class="wap-order-shop-money" v-show="languageName=='en'">
                                    <div class="wap-shop-title">{{val.en_name}}</div>
                                    <div class="wap-shop-txt">{{val.en_spect_name}}</div>
                                    <div class="wap-shop-money">{{$t('message.m')}}{{val.price}}</div>
                                </div>
                                <div class="wap-order-shop-money" v-show="languageName=='it'">
                                    <div class="wap-shop-title">{{val.it_name}}</div>
                                    <div class="wap-shop-txt">{{val.it_spect_name}}</div>
                                    <div class="wap-shop-money">{{$t('message.m')}}{{val.price}}</div>
                                </div>
                                <div class="wap-order-shop-number">&times;{{val.goods_num}}</div> 
                            </div>
                        </div>
                    </div>
                    <div class="wap-order-money">
                        <div class="wap-cen">
                            <div class="wap-money" v-show="(item&&item.totalaams!='0.00')">AAMS{{$t('message.m')}}{{item.totalaams}}</div>
                            <div class="wap-money" v-show="(item&&item.totaliva!='0.00')">IVA{{$t('message.m')}}{{item.totaliva}}</div>
                            <div class="wap-money">{{$t('message.paymoeny')}} {{$t('message.m')}}{{item.totalmoney}}</div>
                            <div class="wap-paytype">
                                <span v-show="item.type2==1">【{{$t('message.zxpay')}}】</span>
                                <span class="wap-red" v-show="item.type2==2">【{{$t('message.hdfkpay')}}】</span>
                            </div>
                            <div class="wap-post" v-show="item.is_no_postage!=1">({{$t('message.haspost')}}：{{$t('message.m')}}{{item.postage}})</div>
                        </div>
                    </div>
                    <div class="wap-order-more">
                        <div class="wap-cen" v-show="item.order_status==0"></div>
                        <div class="wap-cen" v-show="item.order_status==1">
                            <div class="wap-paybtn" @click="getSubmit(item)">{{$t('message.nowpay')}}</div>
                            <div class="wap-info-order">
                                <span class="lias" @click="getInfoOrder(item)">{{$t('message.orinfo')}}</span>
                            </div>
                        </div>
                        <div class="wap-cen" v-show="item.order_status==2">
                            <div class="wap-info-order"><router-link class="lias" :to="{name:'myorderinfo',query:{order_no:item.order_no}}">{{$t('message.orinfo')}}</router-link></div>
                        </div>
                        <div class="wap-cen" v-show="item.order_status==3">
                            <div class="wap-paybtn" @click="getSubmitReceiving(item)">{{$t('message.comfiresh')}}</div>
                            <div class="wap-info-order"><router-link class="lias" :to="{name:'logistics',query:{order_no:item.order_no}}">{{$t('message.loginfo')}}</router-link></div>
                            <div class="wap-info-order"><router-link class="lias" :to="{name:'myorderinfo',query:{order_no:item.order_no}}">{{$t('message.orinfo')}}</router-link></div>
                        </div>
                        <div class="wap-cen" v-show="item.order_status==4">
                            <div class="wap-info-order"><router-link class="lias" :to="{name:'logistics',query:{order_no:item.order_no}}">{{$t('message.loginfo')}}</router-link></div>
                            <div class="wap-info-order" @click="getDelete(item,index)">{{$t('message.delorder')}}</div>
                            <div class="wap-info-order" v-show="item.type2==1">
                                <router-link class="lias" :to="{name:'myordershowinfo',query:{order_no:item.order_no,type:1}}">{{$t('message.shoinfo')}}</router-link>
                            </div>
                            <div class="wap-info-order"><router-link class="lias" :to="{name:'myorderinfo',query:{order_no:item.order_no}}">{{$t('message.orinfo')}}</router-link></div>
                        </div>
                        <div class="wap-cen" v-show="item.order_status==5">
                            <div class="wap-info-order" v-show="item.type2==1"><router-link class="lias" :to="{name:'myordershowinfo',query:{order_no:item.order_no,type:3}}">{{$t('message.showstep')}}</router-link></div>
                            <div class="wap-info-order"><router-link class="lias" :to="{name:'myorderinfo',query:{order_no:item.order_no}}">{{$t('message.orinfo')}}</router-link></div>
                        </div>
                    </div>
                </div>
                <div class="wap-page" v-show="totalPage>currentPage" @click="getPage">{{$t('message.seemore')}}</div>
            </div>
        </b-container>
        <!-- 删除提示弹窗 -->
        <b-modal size="sm" centered v-model="dellaiog" hide-footer :title="$t('message.tiptitle')">
            <div class="d-block text-center">
                <p style="padding:30px 0;">{{$t('message.comfirdel')}}</p>
            </div>
            <b-button  block @click="toggleModal">{{$t('message.firm')}}</b-button>
        </b-modal>
        <!-- 支付类型提示弹窗 -->
        <b-modal size="sm" centered v-model="paylaiog" hide-footer :title="$t('message.pay')">
            <div class="d-block text-center">
                <div class="pc-input_odd">
                    <div class="pc-pay-type" v-for="(item,index) in paylist" :key="index">
                        <div class="pc-pay-otem"><el-radio v-model="radio" :label="item.id">{{item.name}}</el-radio></div>
                    </div>
                </div>
                <div class="pc-link">
                    <span class="pc-linkbtn" @click="payModal('web')">{{$t('message.paytotal')}}</span>
                    <span class="wap-linkbtn" @click="payModal('mobile')">{{$t('message.paytotal')}}</span>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
var setWechatTimer;
import ElementUI from 'element-ui';
import {yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
import label from '@/json/order.json'
export default {
    name:'myorder',
    inject:['reload'],
    data(){
        return{
            radio:'1',
            order_no:'',
            paylist:[],
            languageName:'',
            languageObj:{},
            token:'',
            currentindex:-1,
            currenttabindex:0,
            rows:0,//总条数
            currentPage:1,//当前页
            size:10,//每页条数
            totalPage:0,//总页数
            orderData:[],
            orderList:[],//label.data,
            orderStatusList:[],
            dellaiog:false,
            delobj:{},
            delobjindex:0,
            login_user_info:{},
            paylaiog:false,
            payamount:''
        }
    },
    methods: {
         // 获取系统保底邮费
        getQueryPostage(){
            this.$http.api_order_getpostage().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.payamount = res.data.pay_postage;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 获取支付类型
        getPayTpye(){
            this.$http.api_order_getpricetype().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            var paylistobj = res.data;
                            var paylistarr = [];
                            for(let i in paylistobj) {
                                paylistarr.push({id:i,name:paylistobj[i],check:false});
                            }
                            this.paylist = paylistarr;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 选择支付类型弹窗
        payModal(type){
            let params = {
                token:this.token,//（用户token）、
                orderno:this.order_no,//（订单编号）、
                pricetype:this.radio,//（支付方式 1微信 2支付宝 3visa 4线下支付）、
                isMobile:type?(type=='web'?'0':'1'):'0',//（是否为手机端 1是 0否）
            }
            this.$http.api_order_payorder(params).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.paylaiog = false;
                            // this.reload();
                            if(res.data.redirect_url){
                                // 轮询订单支付状态
                                //轮询订单状态是否改变
                                // setWechatTimer = setInterval(() => {
                                //     this.getEachPayInfo();
                                // }, 5000);
                                this.eachPayInfo = res.data;
                                // window.open(res.data.redirect_url, '_blank');
                                window.location.href = res.data.redirect_url;
                                // setTimeout(() => {
                                //     window.location.reload();
                                // }, 1000);
                                // location = res.data.redirect_url;
                            }
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 删除订单
        getDelete(item,index){
            this.delobj = item;
            this.delobjindex = index;
            this.dellaiog = !this.dellaiog;
        },
        hideModal() {
            this.dellaiog = !this.dellaiog;
        },
        toggleModal() {
            this.$http.api_order_deleteorder({
                token:this.token,
                orderno:this.delobj.order_no
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.orderData.forEach((v,k)=>{
                                if(this.delobjindex == k){
                                    this.orderData.splice(k,1);
                                }
                            });
                            this.dellaiog = false;
                            ElementUI.Message({
                                message:this.languageObj.delsuccess,
                                type:'success',
                                center: true,
                                offset:10,
                            });
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                    }
                }
            });
        },
        // 确认收货
        getSubmitReceiving(item){
            if(this.token&&item){
                // 企业用户
                if(this.login_user_info.is_company==1){
                    if(!this.login_user_info.cinfo.company_name){
                        this.$router.push({path:'/uinfo',query:{type:3}});
                    }else{
                        this.$http.api_order_confirmorder({
                            token:this.token,
                            orderno:item.order_no
                        }).then((res)=>{
                            if(res){
                                if(res.code==1){
                                    if(res.data){
                                        this.orderData.map((v)=>{
                                            if(v.id==item.id){
                                                v.order_status = 4;
                                            }
                                        });
                                    }
                                }else if(res.code==-1){
                                    this.$store.commit('setLoginNameStatus', {});
                                    delLocalItem('login_user_info');
                                    this.$root.Bus.$emit('userInfoClick', {});
                                    this.$store.commit('setLoginStatus',true);
                                    this.$router.push({path:'/'});
                                }else{
                                    ElementUI.Message({
                                        message:res.message,
                                        type:'error',
                                        center: true,
                                        offset:10,
                                    });
                                    return false;
                                }
                            }
                        });
                    }
                }else{
                    // 普通用户
                    if(!this.login_user_info.family_name){
                        this.$router.push({path:'/uinfo',query:{type:1}});
                    }else{
                        this.$http.api_order_confirmorder({
                            token:this.token,
                            orderno:item.order_no
                        }).then((res)=>{
                            if(res){
                                if(res.code==1){
                                    if(res.data){
                                        this.orderData.map((v)=>{
                                            if(v.id==item.id){
                                                v.order_status = 4;
                                            }
                                        });
                                    }
                                }else if(res.code==-1){
                                    this.$store.commit('setLoginNameStatus', {});
                                    delLocalItem('login_user_info');
                                    this.$root.Bus.$emit('userInfoClick', {});
                                    this.$store.commit('setLoginStatus',true);
                                    this.$router.push({path:'/'});
                                }else{
                                    ElementUI.Message({
                                        message:res.message,
                                        type:'error',
                                        center: true,
                                        offset:10,
                                    });
                                    return false;
                                }
                            }
                        });
                    }
                }
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        // 查看详情
        getInfoOrder(item){
            if(item){
                clearInterval(setWechatTimer);
                this.$router.push({path:'myorderinfo',query:{order_no:item.order_no}});
            }
        },
        // 立即支付
        getSubmit(item){
            // 企业用户
            if(this.login_user_info.is_company==1){
                if(!this.login_user_info.cinfo.company_name){
                    this.$router.push({path:'/uinfo',query:{type:3}});
                }else{
                    clearInterval(setWechatTimer);
                    this.paylaiog = !this.paylaiog;
                    this.radio = String(item.price_type);
                    this.order_no = item.order_no;
                }
            }else{
                // 普通用户
                if(!this.login_user_info.family_name){
                    this.$router.push({path:'/uinfo',query:{type:1}});
                }else{
                    clearInterval(setWechatTimer);
                    this.paylaiog = !this.paylaiog;
                    this.radio = String(item.price_type);
                    this.order_no = item.order_no;
                }
            }
            // if(!this.login_user_info.family_name){
            //     this.$router.push({path:'/uinfo',query:{type:1}});
            // }else{
            //     clearInterval(setWechatTimer);
            //     this.paylaiog = !this.paylaiog;
            //     this.radio = String(item.price_type);
            //     this.order_no = item.order_no;
            //     // this.$router.push({path:'/shopfrom',query:{
            //     //     orderno:item.order_no,
            //     //     type:'order',
            //     // }});
            //     /*// 获取订单详情
            //     this.$http.api_order_getorderinfo({
            //         token:this.token,
            //         orderno:item.order_no
            //     }).then((res)=>{
            //         if(res){
            //             if(res.code==1){
            //                 if(res.data){
            //                     let params = {
            //                         token:this.token,//（用户token）、
            //                         orderno:item.order_no,//（订单编号）、
            //                         pricetype:res.data.price_type,//（支付方式 1微信 2支付宝 3visa 4线下支付）、
            //                         isMobile:tp?(tp=='web'?'0':'1'):'0',//（是否为手机端 1是 0否）
            //                     }
            //                     this.$http.api_order_payorder(params).then((resp)=>{
            //                         if(resp){
            //                             if(resp.code==1){
            //                                 if(resp.data){
            //                                     if(resp.data.redirect_url){
            //                                         this.eachPayInfo = resp.data;
            //                                         window.open(resp.data.redirect_url, '_blank');
            //                                     }
            //                                     // 轮询订单支付状态
            //                                     //轮询订单状态是否改变
            //                                     setWechatTimer = setInterval(() => {
            //                                         this.getEachPayInfo();
            //                                     }, 5000);
            //                                 }
            //                             }else if(resp.code==-1){
            //                                 this.$store.commit('setLoginNameStatus', {});
            //                                 delLocalItem('login_user_info');
            //                                 this.$root.Bus.$emit('userInfoClick', {});
            //                                 this.$store.commit('setLoginStatus',true);
            //                                 this.$router.push({path:'/'});
            //                             }else{
            //                                 ElementUI.Message({
            //                                     message:resp.message,
            //                                     type:'error',
            //                                     center: true,
            //                                     offset:10,
            //                                 });
            //                                 return false;
            //                             }
            //                         }
            //                     });
            //                 }
            //             }else if(res.code==-1){
            //                 this.$store.commit('setLoginNameStatus', {});
            //                 delLocalItem('login_user_info');
            //                 this.$root.Bus.$emit('userInfoClick', {});
            //                 this.$store.commit('setLoginStatus',true);
            //                 this.$router.push({path:'/'});
            //             }else{
            //                 ElementUI.Message({
            //                     message:res.message,
            //                     type:'error',
            //                     center: true,
            //                     offset:10,
            //                 });
            //                 return false;
            //             }
            //         }
            //     });*/
            // }
        },
        // 轮循订单支付状态
        getEachPayInfo(){
            this.$http.api_order_orderquery({
                token:this.token,//（用户token）、
                orderno:this.eachPayInfo.order_no,//（订单编号）、
                transactionnumber:this.eachPayInfo.transaction_no
            }).then((respone)=>{
                if(respone){
                    if(respone.code==1){
                        if(respone.data){
                            clearInterval(setWechatTimer);
                        }
                    }else if(respone.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        // ElementUI.Message({
                        //     message:respone.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        return false;
                    }
                }
            });
        },
        // 切换订单状态查看数据（目前订单状态没有入库搜索）
        getSwitchTab(index){
            this.currentindex = index;
            this.currentPage = 1;
            this.getOrderList(1);
        },
        getSwitchTabWap(index){
            this.currenttabindex = index;
            let key = index;
            if(key==0){
                key = -1;
            }
            this.currentindex = key;
            this.currentPage = 1;
            this.getOrderList(1);
        },
        // 分页
        handleCurrentChange(page){
            this.getOrderList(page)
        },
        // wap分页
        getPage(){
            if(this.currentPage<this.totalPage){
                this.currentPage = this.currentPage+1;
            }
            this.$http.api_order_getorderlist({
                token:this.token,
                status:this.currentindex,
                page:this.currentPage,
                size:this.size
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.rows = res.data.count;
                            this.totalPage = res.data.totalpage;
                            res.data.list.map((v)=>{
                                var money = 0;
                                v.goods.map((i)=>{
                                    i.moneyAll = i.goods_num*i.price;
                                    money += i.moneyAll;
                                });
                                v.moneyAll = money
                            });
                            if(this.currentPage>1){
                                this.orderList = this.orderList.concat(res.data.list);
                            }else{
                                this.orderList = res.data.list;
                            }
                            if(this.rows<this.size){
                                return;
                            }
                            if(this.currentPage == this.totalPage){
                                return;
                            }
                            this.orderList = res.data.list;
                            this.orderData = this.orderList;
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        this.orderData = [];
                        this.totalPage = 0;
                        this.rows = 0;
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        return false;
                    }
                }
            });
        },
        // 订单列表状态
        getStateList(){
            this.$http.api_datalist_orderstatuslist().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.orderStatusList = res.data;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 订单列表数据
        getOrderList(page){
            this.orderData = [];
            this.$http.api_order_getorderlist({
                token:this.token,
                status:this.currentindex,
                page:page,
                size:this.size
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.rows = res.data.count;
                            this.totalPage = res.data.totalpage;
                            res.data.list.map((v,k)=>{
                                // res.data.list[k].totalaams
                                // res.data.list[k].totaliva
                                // totalaams: "2.600000"
                                // totaliva: "2.770000"
                                let aamsstr_arr = []
                                let ivastr_arr = []
                                if(res.data.list[k].totalaams){
                                    aamsstr_arr = res.data.list[k].totalaams.split('.')
                                    v.totalaams = aamsstr_arr[0]+'.'+aamsstr_arr[1].slice(0,2)
                                }
                                if(res.data.list[k].totaliva){
                                    ivastr_arr = res.data.list[k].totaliva.split('.')
                                    v.totaliva = ivastr_arr[0]+'.'+ivastr_arr[1].slice(0,2)
                                }
                                var money = 0;
                                v.goods.map((i)=>{
                                    i.moneyAll = i.goods_num*i.price;
                                    money += i.moneyAll;
                                });
                                v.moneyAll = money
                            });
                            this.orderList = res.data.list;
                            this.orderData = this.orderList;
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        this.orderData = [];
                        this.totalPage = 0;
                        this.rows = 0;
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        return false;
                    }
                }
            });
        },
        // 查看商品详情
        getShopInfo(item){
            if(item.goods_id){
                this.$router.push({path:'/info',query:{shopid:item.goods_id}});
            }
        }
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "logintip":"请先登录",
                "delsuccess":"删除成功",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "logintip":"please log in first",
                "delsuccess":"Successfully deleted",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "logintip":"fai prima il log in",
                "delsuccess":"Eliminato con successo",
            }
        }
        let login_user_info = this.$store.state.data.loginNameStatus.family_name?this.$store.state.data.loginNameStatus:(getLocalItem('login_user_info')?getLocalItem('login_user_info'):null);
        if(login_user_info){
            this.login_user_info = login_user_info;
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
            this.getStateList();// 订单列表状态
            this.getOrderList(1);// 订单列表
            this.getPayTpye();
            // this.getQueryPostage();
        }else{
            this.$router.push({path:'/'});
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
.wap-page{
    display: flex;
    -webkit-box-orient: horizontal;
    justify-content: center;
    padding:20px 0;
}
.text-center{
    text-align: left !important;
    .pc-title{
        font-size:18px;
        padding-bottom: 10px;
    }
    .pc-input_odd{
        border:1px solid #eee;
        border-radius:5px;
        margin-bottom: 20px;
        background: #fff;
        .pc-pay-type{
            padding:10px 0 0;
            .pc-pay-otem{
                padding:0px 15px 10px;
                border-bottom:1px solid #eee;
                position: relative;
                .pc-icon-img{
                    position: absolute;
                    right:10px;
                    top:0;
                    display: inline-block;
                    width:70px;
                    height:30px;
                    .pc-payicon{
                        display: inline-block;
                        width: 30px;
                        height:22px;
                        margin-right: 10px;
                        vertical-align: middle;
                        &:last-child{
                            margin-right: 0px;

                        }
                    }
                    
                    .pc-visa{
                        background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .pc-load{
                        background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    
                }
                /deep/.el-radio__inner{
                    width:20px;
                    height:20px;
                }
                /deep/.el-radio__label{
                }
                /deep/.el-radio__input.is-checked .el-radio__inner{
                    border-color: #1f73b7;
                    background: #1f73b7;
                }
                /deep/.el-radio__input.is-checked+.el-radio__label{
                    color: #222;
                }
            }
            &:last-child{
                .pc-pay-otem{
                    border-bottom:none;
                }
                
            }
            .pc-pay-input{
                background: #f4f7fe;
                padding:10px 20px;
                .pc-input-two{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    height: 58px;
                    width: 100%;
                    margin-bottom: 20px;
                    .pc-t{width:4%}
                    /deep/.el-input--prefix .el-input__inner{
                        padding-left: 20px;
                    }
                    .el-input{
                        width: 48%;
                        height: 100%;
                        /deep/.el-input__inner{
                            height: 100%;
                            color: #95959E;
                            border:1px solid #DDE0ED;
                        }
                        
                        // WebKit 谷歌
                        /deep/input.el-input__inner::-webkit-input-placeholder {
                            color: #95959E;
                        }
                        // Mozilla Firefox 4 - 18 适配火狐
                        /deep/input.el-input__inner:-moz-placeholder { 
                            color: #95959E;
                        }
                        // Mozilla Firefox 19+ 适配火狐
                        /deep/input.el-input__inner::-moz-placeholder {
                            color: #95959E;
                        }
                        // IE 10+  
                        /deep/input.el-input__inner:-ms-input-placeholder { 
                            color: #95959E
                        }
                        /deep/.el-input__icon{display: none;}
                    }
                    input{
                        height: 100%;
                        width: 48%;
                        border:1px solid #DDE0ED;
                        outline: none;
                        padding:0 20px;
                        border-radius:5px;
                        background: #fff;
                        // margin: 0 2%;
                    }
                    // WebKit 谷歌
                    /deep/input::-webkit-input-placeholder {
                        color: #95959E;
                    }
                    // Mozilla Firefox 4 - 18 适配火狐
                    /deep/input:-moz-placeholder { 
                        color: #95959E;
                    }
                    // Mozilla Firefox 19+ 适配火狐
                    /deep/input::-moz-placeholder {
                        color: #95959E;
                    }
                    // IE 10+  
                    /deep/input:-ms-input-placeholder { 
                        color: #95959E
                    }
                }
            }
        }
    }
    .pc-link{
        height: 40px;
        width: 100%;
        text-align: center;
        .pc-linkbtn{
            display: inline-block;
            padding:12px 30px;
            border-radius:4px;
            background: #222;
            color: #fff;
            cursor: pointer;
        }
        .wap-linkbtn{
            display: inline-block;
            padding:12px 30px;
            border-radius:4px;
            background: #222;
            color: #fff;
            cursor: pointer;
        }
    }
}

// pc端分页样式优化
/deep/.page-item{
    .page-link:focus, 
    .page-link:active:focus, 
    .page-link.active:focus,
    .page-link.focus,
    .page-link:active.focus,
    .page-link.active.focus {
        outline: none;
        box-shadow:none;
    }
    &:nth-child(1){
        display: none;
        .page-link{
            color: transparent;
        }
        .page-link::before{
            content: '首页';
            padding-left:8px;
            color: #616166;
        }
    }
    &:nth-child(2){
        // .page-link{
        //     color: transparent;
        // }
        
        .page-link::before{
            
            // content: v-bind(langageprev);//'上一页';
            padding-left:5px;
            color: #616166;
        }
    }
    &:last-child{
        display: none;
        .page-link{
            color: transparent;
        }
        .page-link::before{
            content: '尾页';
            padding-left:8px;
            color: #616166;
        }
    }
    &:nth-last-child(2){
        // .page-link{
        //     color: transparent;
        // }
        .page-link::before{
            // content: v-bind(langagenext);//'下一页';
            padding-left:5px;
            color: #616166;
        }
    }
}
/deep/.page-item:first-child .page-link{
    border-radius: 0;
}
/deep/.page-item:last-child .page-link{
    border-radius: 0;
}
/deep/.page-link{
    border:1px solid #DDE0ED;
    margin: 0 4px;
    padding:6px 10px;
    
    color: #616166;
}
/deep/.page-item.active .page-link{
    color: #007bff;
    background-color: #fff;
    border-color: #fff;
    outline: none;
}
.pc-page{
    display: flex;
    -webkit-box-orient: horizontal;
    justify-content: center;
    padding:20px 0;
}
.clear{
    clear: both;
}
.wap-navtab{
    padding:20px 10px 10px;
    
}
.wap-label{
    padding: 0 0 10px;
    /deep/.van-tab .van-tab__text{
        font-size:16px;
    }
    /deep/.van-tabs__line {
        // position: absolute;
        // bottom: 15px;
        // left: 0;
        // z-index: 1;
        // width: 40px;
        height: 2px;
        background-color: #2E2E2E;
        // border-radius: 3px;
    }
}
.pc-shop-list{
    padding:20px 10px 0;
    .label-tit{
        padding:20px 0 0;
        font-size:16px;
        .label{
            cursor: pointer;
            display: inline-block;
            color: #616166;
            margin: 0 10px;
            padding:0 10px 0 20px;
            vertical-align: middle;
        }
        .cur{
            color: #1F73B7;
            border-left:2px solid #1F73B7;
        }
    }
}

.order-group-nodata{
    width: 100%;
    padding:20px 0;
    text-align: center;
}
.order-header{
    padding:7px 0;
    background: #f4f7fe;
    display: flex;
    -webkit-box-orient: horizontal;
    .head-th{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #616166;
        padding:3px 0;
    }
    .col1{
        width:48%;
        border-right: 1px solid #dde0ed;
    }
    .col2{
        width:11%;
        border-right: 1px solid #dde0ed;
    }
    .col3{
        width:11%;
        border-right: 1px solid #dde0ed;
    }
    .col4{
        width:15%;
        border-right: 1px solid #dde0ed;
    }
    .col5{
        width:15%;
    }
}
.shop-group-box{
    padding:20px 10px 0;
    width: 100%;
    .order-tab{
        display: flex;
        -webkit-box-orient: horizontal;
        margin-bottom: 20px;
        .tab-name{
            cursor: pointer;
            margin-left:10px;
            padding:4px 20px;
            
        }
        .cur{
            border-bottom: 2px solid #222;
        }
    }
    .order-group{
        width: 100%;
        padding:20px 0;
        .title{
            display: flex;
            width: 100%;
            -webkit-box-orient: horizontal;
            padding:10px 0;
            .orderleft{
                width:70%;
                display: flex;
                -webkit-box-orient: vertical;
                .time{
                    color: #bcbcc2;
                    
                }
                .ordercode{
                    padding-left: 20px;
                    
                }
            }
            .orderstatus{
                width:30%;
                color: #1f73b7;
                text-align: right;
                
            }
        }
        .order-wrap{
            border-top: 1px solid #dde0ed;
            border-bottom: 1px solid #dde0ed;
            display: flex;
            width: 100%;
            -webkit-box-orient: horizontal;
            margin:0 0 20px 0;
            .order-shop{
                width:70%;
                border-right:1px solid #dde0ed;
                .order-shop-item{
                    display: flex;
                    width: 100%;
                    -webkit-box-orient: horizontal;
                    border-top:1px solid #dde0ed;
                    &:first-child{
                        border-top: none;
                    }
                    .order-shop-img{
                        cursor: pointer;
                        width:70%;
                        display: flex;
                        -webkit-box-orient: horizontal;
                        padding: 20px 0;
                        border-right:1px solid #dde0ed;
                        .shop-img{
                            width:30%;
                            img{
                                width:80%;
                                height:100px;
                            }
                        }
                        .shop-title{
                            width:70%;
                            display: flex;
                            align-items: center;
                        }
                    }
                    .order-shop-money{
                        width:15%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        border-right:1px solid #dde0ed;
                        color: #7d7d80;
                        
                    }
                    .order-shop-number{
                        width:15%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        text-align: center;
                        color: #7d7d80;
                    }
                }
            }
            .order-money{
                width:15%;
                display: flex;
                -webkit-box-orient: vertical;
                align-items: center;
                justify-content: center;
                border-right:1px solid #dde0ed;
                .cen{
                    .money{
                        font-weight: bold;
                        text-align: center;
                        
                    }
                    .paytype{
                        padding:2px 0;
                        text-align: center;
                        .red{
                            color:#bf0000;

                        }
                    }
                    .post{
                        
                        color:#95959e;
                    }
                }
            }
            .order-more{
                width:15%;
                display: flex;
                -webkit-box-orient: vertical;
                align-items: center;
                justify-content: center;
                .cen{
                    .paybtn{
                        cursor: pointer;
                        color:#fff;
                        background: #222;
                        padding:5px 20px;
                        // 
                        margin:3px 0;
                    }
                    .info-order{
                        cursor: pointer;
                        color: #616166;
                        text-align: center;
                        padding:3px 0;
                        // font-size:16px;
                        // a{
                        //     font-size: 16px !important;
                        // }
                    }
                }
            }
        }
    }
}

/deep/a{
    color:#616166 ;
    text-decoration: none;
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .text-center{
        .pc-link{
            .wap-linkbtn{display: none;}
        }
    }
    .wrap{
        .wap-shop-list{display: none;}
        
        .wap-navtab{display: none;}
        .wap-label{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .text-center{
        .pc-link{
            .wap-linkbtn{display: none;}
        }
    }
    .wrap{
        .wap-shop-list{display: none;}
        
        .wap-navtab{display: none;}
        .wap-label{display: none;}
        .shop-group-box .order-group .order-wrap .order-shop .order-shop-item .order-shop-img .shop-title{}
        .shop-group-box .order-group .order-wrap .order-more .cen .paybtn{padding:5px 10px;}
        .shop-group-box .order-group .order-wrap .order-money .cen .money{}
        .shop-group-box .order-group .order-wrap .order-money .cen .paytype span{}
        .shop-group-box .order-group .order-wrap .order-money .cen .post{}
        
        .shop-group-box .order-group .order-wrap .order-more .cen .info-order a{}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .text-center{
        .pc-link{
            .wap-linkbtn{display: none;}
        }
    }
    .wrap{
        .wap-shop-list{display: none;}
        
        .wap-navtab{display: none;}
        .wap-label{display: none;}
        .shop-group-box .order-group .order-wrap .order-shop .order-shop-item .order-shop-img .shop-title{}
        .shop-group-box .order-group .order-wrap .order-more .cen .paybtn{padding:5px 10px;}
        .shop-group-box .order-group .order-wrap .order-money .cen .money{}
        .shop-group-box .order-group .order-wrap .order-money .cen .paytype span{}
        .shop-group-box .order-group .order-wrap .order-money .cen .post{}
        
        .shop-group-box .order-group .order-wrap .order-more .cen .info-order a{}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .text-center{
        .pc-link{
            .pc-linkbtn{display: none;}
        }
    }
    .wrap{
        .pc-shop-list{display: none;}
        
        
        .wap-shop-list{
            padding: 0 0 0 0;
            width: 100%;
            background: #f4f7fe;
            .wap-order-group{
                width: 100%;
                .wap-order-item{
                    margin: 0 0 15px;
                    padding:0px 10px 10px;
                    background: #fff;
                
                    .wap-title{
                        display: flex;
                        width: 100%;
                        -webkit-box-orient: horizontal;
                        padding:10px 0;
                        .wap-orderleft{
                            width:70%;
                            display: flex;
                            -webkit-box-orient: vertical;
                            .wap-time{
                                color: #bcbcc2;
                                
                            }
                            .wap-ordercode{
                                
                            }
                        }
                        .wap-orderstatus{
                            width:30%;
                            color: #1f73b7;
                            text-align: right;
                            
                        }
                    }
                    .wap-order-money{
                        width:100%;
                        
                        .wap-cen{
                            display: flex;
                            -webkit-box-orient: vertical;
                            justify-content: flex-end;
                            padding:2px 0px;
                            .wap-money{
                                display: inline-block;
                                
                                margin-left: 5px;
                            }
                            .wap-paytype{
                                display: inline-block;
                                span{
                                    
                                }
                                .wap-red{
                                    color:#bf0000;
                                    
                                }
                                vertical-align: middle;
                            }
                            .wap-post{
                                display: inline-block;
                                
                                color:#95959e;
                            }
                        }
                    }
                    .wap-order-more{
                        width:100%;
                        display: flex;
                        -webkit-box-orient: vertical;
                        align-items: center;
                        justify-content: flex-end;
                        .wap-cen{
                            width: 100%;
                            display: flex;
                            -webkit-box-orient: horizontal;
                            justify-content: flex-end;
                            .wap-paybtn{
                                cursor: pointer;
                                color:#fff;
                                background: #222;
                                padding:3px 13px;
                                
                                margin:3px 3px;
                            }
                            .wap-info-order{
                                cursor: pointer;
                                color: #616166;
                                text-align: center;
                                padding:3px 13px;
                                
                                margin:3px;
                                border:1px solid #dde0ed;
                                a{color: #616166;}
                            }
                        }
                    }
                    .wap-order-wrap{
                        display: flex;
                        width: 100%;
                        -webkit-box-orient: horizontal;
                        margin:0 0 10px 0;
                        .wap-order-shop{
                            width:100%;
                            .wap-order-shop-item{
                                display: flex;
                                width: 100%;
                                -webkit-box-orient: horizontal;
                                margin: 10px 0 0;
                                .wap-order-shop-img{
                                    width:30%;
                                    display: flex;
                                    -webkit-box-orient: horizontal;
                                    padding:0 10px 0;
                                    img{
                                        width:100%;
                                        height:100px;
                                    }
                                
                                }
                                .wap-order-shop-money{
                                    width:50%;
                                    
                                    .wap-shop-title{
                                        width:100%;
                                    }
                                    .wap-shop-txt{
                                        
                                        color: #7d7d80;
                                    }
                                    .wap-shop-money{
                                        
                                        margin-top:20px;
                                    }
                                }
                                .wap-order-shop-number{
                                    width:20%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    
                                    text-align: center;
                                    color: #7d7d80;
                                }
                            }
                        }
                        
                        
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .text-center{
        .pc-link{
            .pc-linkbtn{display: none;}
        }
    }
    .wrap{
        .pc-shop-list{display: none;}
        
        .wap-shop-list{
            padding: 0 0 0 0;
            width: 100%;
            background: #f4f7fe;
            .wap-order-group{
                width: 100%;
                .wap-order-item{
                    margin: 0 0 15px;
                    padding:0px 10px 10px;
                    background: #fff;
                
                    .wap-title{
                        display: flex;
                        width: 100%;
                        -webkit-box-orient: horizontal;
                        padding:10px 0;
                        .wap-orderleft{
                            width:70%;
                            display: flex;
                            -webkit-box-orient: vertical;
                            .wap-time{
                                color: #bcbcc2;
                                
                            }
                            .wap-ordercode{
                                
                            }
                        }
                        .wap-orderstatus{
                            width:30%;
                            color: #1f73b7;
                            text-align: right;
                            
                        }
                    }
                    .wap-order-money{
                        width:100%;
                        
                        .wap-cen{
                            display: flex;
                            -webkit-box-orient: vertical;
                            justify-content: flex-end;
                            padding:2px 0px;
                            .wap-money{
                                display: inline-block;
                                margin-left: 5px;
                            }
                            .wap-paytype{
                                display: inline-block;
                                span{
                                    
                                }
                                .wap-red{
                                    color:#bf0000;
                                    
                                }
                                vertical-align: middle;
                            }
                            .wap-post{
                                display: inline-block;
                                
                                color:#95959e;
                            }
                        }
                    }
                    .wap-order-more{
                        width:100%;
                        display: flex;
                        -webkit-box-orient: vertical;
                        align-items: center;
                        justify-content: flex-end;
                        .wap-cen{
                            width: 100%;
                            display: flex;
                            -webkit-box-orient: horizontal;
                            justify-content: flex-end;
                            .wap-paybtn{
                                cursor: pointer;
                                color:#fff;
                                background: #222;
                                padding:3px 13px;
                                
                                margin:3px 3px;
                            }
                            .wap-info-order{
                                cursor: pointer;
                                color: #616166;
                                text-align: center;
                                padding:3px 13px;
                                
                                margin:3px;
                                border:1px solid #dde0ed;
                                a{color: #616166;}
                            }
                        }
                    }
                    .wap-order-wrap{
                        display: flex;
                        width: 100%;
                        -webkit-box-orient: horizontal;
                        margin:0 0 10px 0;
                        .wap-order-shop{
                            width:100%;
                            .wap-order-shop-item{
                                display: flex;
                                width: 100%;
                                -webkit-box-orient: horizontal;
                                margin: 10px 0 0;
                                .wap-order-shop-img{
                                    width:30%;
                                    display: flex;
                                    -webkit-box-orient: horizontal;
                                    padding:0 10px 0;
                                    img{
                                        width:100%;
                                        height:100px;
                                    }
                                
                                }
                                .wap-order-shop-money{
                                    width:50%;
                                    
                                    .wap-shop-title{
                                        width:100%;
                                    }
                                    .wap-shop-txt{
                                        
                                        color: #7d7d80;
                                    }
                                    .wap-shop-money{
                                        
                                        margin-top:20px;
                                    }
                                }
                                .wap-order-shop-number{
                                    width:20%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    
                                    text-align: center;
                                    color: #7d7d80;
                                }
                            }
                        }
                        
                        
                    }
                }
            }
        }
    }
}
</style>